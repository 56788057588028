import en from "/locales/en-US.yml";
import nl from "/locales/nl-NL.yml";

export default defineI18nConfig(() => {
  return {
    legacy: true,
    locale: 'en',
    messages: {
      en,
      nl
    }
  }
})
